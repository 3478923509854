import styled from "styled-components";

export const StyledContainer = styled.div``;

export const StyledPageTitle = styled.div`
  background: #333;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
  align-items: baseline;

  & h1 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
  }
`;

export const StyledOrderBlock = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 20px;

  .table-block table {
    width: 100%;
  }
  & div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    // min-width: fit-content;
  }
  .dMpKVF {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px !important;
  }

  .table-block table th {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
  }
  .table-block table tr:nth-child(even) td {
    background: #f9fcff;
  }
`;
