import ApiService from "./ApiService";

export default class frontService {
  static trackCustomerOrder = (orderData) =>
    ApiService.post(`/front/track-order`, orderData);

  static getDownloadHistory = (data) =>
    ApiService.post(`/front/get-download-history`, data);

  static downloadPassportDocPdf = (data) =>
    ApiService.post(`/front/download-passport-doc-pdf`, data);
}
