import ApiService from "./ApiService";

export default class OrderService {
  static getAllFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count");

  static getUnreadMailCount = () =>
    ApiService.get("/common/get-unread-mail-count");

  static getOrdersList = ({ order }) =>
    ApiService.get(
      `/admin/get-orders-list/${order.orderName}?page=${order.page}&limit=${order.perPage}&delay=1`
    );

  static getOrderDetailsByOrderId = (orderId) =>
    ApiService.get(`/admin/get-orders-details/${orderId}`);

  static getUploadDocDetails = (orderId) =>
    ApiService.get(`/admin/get-upload-doc-details/${orderId}`);

  static deleteOrdersData = (deleteData) =>
    ApiService.put(`/admin/change-order-status`, deleteData);

  static sendRefundMail = (refundData) =>
    ApiService.put("/admin/move-to-complete-refund", refundData);

  static uploadVisaDocument = (data) =>
    ApiService.upload("/admin/upload-visa", data);
  // ApiService.post("/admin/upload-visa-document", data);

  static resendMail = (data) => ApiService.post("/admin/resend-mail", data);

  static defencePack = (orderId) =>
    ApiService.post("/admin/defence-pack-details", orderId);

  static GetDefencePackPdf = (data) =>
    ApiService.post("/admin/get-defence-pack-pdf", data, {
      responseType: "blob",
    });

  static MoveOrderStatus = (data) =>
    ApiService.put(`/admin/move-order-status`, data);
  static searchOrder = (data) => ApiService.post(`/admin/search-order`, data);
  static getCountryOrders = (searchData) =>
    ApiService.post(`/admin/get-country-order-list`, searchData);
  // static getAllUploadedDocOfCustomers = (orderId) =>
  //   ApiService.get(`/admin/get-all-uploaded-doc-of-customers/${orderId}`);
}
