import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  StyledOrderBlock,
  StyledBottomBlock,
  StyledBlockWrapper,
} from "./style";
// import { FaDownload } from "react-icons/fa";
import rightArrow from "../../assests/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetailsByOrderId,
  getUploadDocDetails,
  resendMail,
  uploadVisaDocument,
} from "../../redux/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";
import TransactionModal from "./TransactionModal/TransactionModal";
import DefenceContent from "./DefenceContent/DefenceContent";
import { fileExists } from "../../utility/utility";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);
  const [showDefence, setShowDefence] = useState(false);
  const [showTransaction, SetShowTransaction] = useState(false);
  const [showDefencePack, setShowDefencePack] = useState(false);
  const [mailServiceProvider, setMailServiceProvider] = useState('Zoho');
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const UploadDocDetails = useSelector((state) => state?.order?.uploadDocDetails);
  const isLoading = useSelector((state) => state?.order?.loading);
  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(
    OrderDetails?.applicantInfo?.length > 0
      ? OrderDetails?.applicantInfo[0]?.applicant_email
      : null
  );
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);
  const [formData, setFormData] = useState([
    {
      fullName: ""
    }
  ],
  );
  const [formError, setFormError] = useState({});

  const handleInputChange = (index, event, full_name) => {
    const { name, value, files } = event.target;
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [full_name]: name === 'file' ? files[0] : value,
        fullName: full_name ? full_name : "",
      };
      return newData;
    });
  };

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
      // for displaying last record on the download button for add member in upload doc section
      dispatch(getUploadDocDetails(orderId));
    }

    document.title = "Orders Details | Egypt E-visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.applicantInfo?.length > 0 &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(
        OrderDetails?.applicantInfo?.length > 0
          ? OrderDetails?.applicantInfo[0]?.applicant_email
          : null
      );
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  let uploadArr = {};
  if (UploadDocDetails?.length > 0 && UploadDocDetails !== null && UploadDocDetails != "undefined") {
    for (const item of UploadDocDetails) {
      uploadArr[item?.customer_name] = item?.file1;
    }
  }

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email:
        OrderDetails?.applicantInfo?.length > 0
          ? OrderDetails?.applicantInfo[0]?.applicant_email
          : null,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [fullName, setFullName] = useState("");
  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        let returnResponse = response || response == "true" ? true : false;
        return returnResponse;
      });
    }
  };

  const validation = (valuesForm) => {
    if (valuesForm.length == 1 && valuesForm[0]?.fullName == "") {
      setFormError({ file: "One file must be uploaded." })
      return false;
    }
    return true;
  }

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      if (validation(formData)) {
        const data = new FormData();
        data.append("orderId", values.orderId);
        data.append("email", values.email);
        data.append("mailServiceProvider", mailServiceProvider);
        formData.forEach((user, index) => {
          const fullName = user.fullName;
          const file = user[fullName];
          data.append(`fullName[${index}]`, fullName);
          if (file instanceof File) {
            data.append(`fileUpload`, file);
            data.append(`fileInfo[${fullName}]`, file?.name)
          }
        });
        dispatch(uploadVisaDocument(data))
          .unwrap()
          .then((res) => {
            resetForm();
            if (res.length > 0 && res[0].status === 1) {
              toast.success(`${res[1].message}`, {
                className: "toast-message",
              })
              dispatch(getOrderDetailsByOrderId(orderId));
              dispatch(getUploadDocDetails(orderId));
            } else {
              toast.error("Document is not uploaded successfully.", {
                className: "toast-message",
              })
            }

            // if (res.status === 1) {
            //   toast.success(`${res.message}`, {
            //     className: "toast-message",
            //   });
            //   dispatch(getOrderDetailsByOrderId(orderId));
            //   dispatch(getUploadDocDetails(orderId));
            // }
          });
      }
    },
  });

  const handleResendMail = (e, serviceProvider) => {
    e.preventDefault();
    setMailServiceProvider(serviceProvider);
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
      mailServiceProvider: serviceProvider
    };
    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
          dispatch(getUploadDocDetails(orderId));
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>#</th>
                      <th style={{ width: "10%" }}>Order ID</th>
                      <th style={{ width: "30%" }}>Download</th>
                      <th style={{ width: "25%" }}>Date & Time</th>
                      <th style={{ width: "30%" }}>Mail Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          <tr>
                            <td key={index}>
                              <a
                                href={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${item?.uploadDoc?.file1}`}
                                className="blue-btn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download File -
                                <span>
                                  {`${item?.uploadDoc?.customer_name}`}
                                </span>
                              </a>
                            </td>
                          </tr>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                          <td>{item?.uploadDoc?.mail_service_provider}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "Zoho");
                      }}
                    >
                      Resend Mail(Zoho){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn_aws"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "AWS");
                      }}
                    >
                      Resend Mail(AWS){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>

                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn_sendgrid"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "Sendgrid");
                      }}
                    >
                      Resend Mail(Sendgrid){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                          disabled
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>
                    {OrderDetails?.applicantInfo?.length !== 0 ? (
                      OrderDetails?.applicantInfo?.map((data, index) => {
                        return (
                          <tr>
                            <td>
                              PDF - {data?.first_name}
                            </td>
                            <tr>
                              <td>
                                <label className="file-upload">
                                  <input
                                    type="file"
                                    className=""
                                    id="fileUpload"
                                    name="file"
                                    accept=".pdf,image/*"
                                    disabled={values.fileUpload !== ""}
                                    onChange={(e) => {
                                      // setFieldValue(
                                      //   "fileUpload",
                                      //   e.currentTarget.files[0]
                                      // )
                                      handleInputChange(index, e, data?.first_name)
                                      setFullName(data?.first_name)
                                      setFormError({ file: "" })
                                    }
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <span>Choose File</span>
                                </label>
                                <span className="slcted-file"></span>
                                {formError.file ? (<p>{formError.file}</p>) : null}
                                {/* <p>{errors.fileUpload}</p> */}
                              </td>
                            </tr>


                            <td>
                              {(OrderDetails?.uploadDoc?.length !== 0 && uploadArr.length !== 0 && uploadArr[data?.first_name]) && (
                                <a
                                  href={
                                    OrderDetails?.uploadDoc !== undefined
                                      ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${uploadArr[data?.first_name] ? uploadArr[data?.first_name] : ""}`
                                      : null
                                  }
                                  className="blue-btn"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Download File-
                                  <span>
                                    {data?.first_name}
                                  </span>
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>PDF</td>
                        <tr>
                          <td>
                            <label className="file-upload">
                              <input
                                type="file"
                                className=""
                                id="fileUpload"
                                name="fileUpload"
                                accept=".pdf,image/*"
                                onChange={(e) =>
                                  setFieldValue(
                                    "fileUpload",
                                    e.currentTarget.files[0]
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <span>Choose File</span>
                            </label>
                            <span className="slcted-file"></span>
                            <p>{errors.fileUpload}</p>
                          </td>
                        </tr>
                      </tr>
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('Zoho')}
                >
                  Submit (Zoho) {" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn_aws"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('AWS')}
                >
                  Submit (AWS) {" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn_sendgrid"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('Sendgrid')}
                >
                  Submit (SendGrid){" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>User Information</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.application_type && (
                    <tr>
                      <td>Applicant Type</td>
                      <td>{OrderDetails?.application_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.visa_type && (
                    <tr>
                      <td>Visa Type</td>
                      <td>{OrderDetails?.visa_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.entry_type && (
                    <tr>
                      <td>Entry Type</td>
                      <td>{OrderDetails?.entry_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.travelling_from && (
                    <tr>
                      <td>Travelling From</td>
                      <td>{OrderDetails?.travelling_from}</td>
                    </tr>
                  )}

                  {OrderDetails?.expected_arrival_date && (
                    <tr>
                      <td>Expected Arrival Date (MM/DD/YYYY)</td>
                      <td>{OrderDetails?.expected_arrival_date}</td>
                    </tr>
                  )}

                  {OrderDetails?.expected_departure_date && (
                    <tr>
                      <td>Expected Departure Date (MM/DD/YYYY)</td>
                      <td>{OrderDetails?.expected_departure_date}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledBlockWrapper>
            <h2>Applicant Information</h2>
            <div className="col-md-12">
              {OrderDetails?.applicantInfo?.length !== 0 &&
                OrderDetails?.applicantInfo?.map((item, index) => {
                  return (
                    <>
                      <StyledOrderBlock key={index}>
                        <h2>Applicant {index + 1} Information</h2>
                        <div className="table-block table-responsive">
                          <table>
                            <tbody>
                              {item?.first_name && (
                                <tr>
                                  <td>First / Given Name (s)</td>
                                  <td>{item?.first_name}</td>
                                </tr>
                              )}

                              {item?.sur_name && (
                                <tr>
                                  <td>Surname(s)</td>
                                  <td>{item?.sur_name}</td>
                                </tr>
                              )}

                              {item?.gender && (
                                <tr>
                                  <td>Gender</td>
                                  <td>{item?.gender}</td>
                                </tr>
                              )}

                              {item?.dob && (
                                <tr>
                                  <td>Date of Birth</td>
                                  <td>{item?.dob}</td>
                                </tr>
                              )}

                              {item?.country_of_birth && (
                                <tr>
                                  <td>Country of Birth</td>
                                  <td>{item?.country_of_birth}</td>
                                </tr>
                              )}

                              {item?.current_nationality && (
                                <tr>
                                  <td>Current Nationality</td>
                                  <td>{item?.current_nationality}</td>
                                </tr>
                              )}

                              {item?.original_nationality && (
                                <tr>
                                  <td>Original Nationality</td>
                                  <td>{item?.original_nationality}</td>
                                </tr>
                              )}

                              {item?.other_nationality_status && (
                                <tr>
                                  <td>Do you have other nationalities?</td>
                                  <td>{item?.other_nationality_status}</td>
                                </tr>
                              )}

                              {item?.other_nationality && (
                                <tr>
                                  <td>Other nationalities</td>
                                  <td>{item?.other_nationality}</td>
                                </tr>
                              )}

                              {item?.profession && (
                                <tr>
                                  <td>Profession</td>
                                  <td>{item?.profession}</td>
                                </tr>
                              )}

                              {item?.marital_status && (
                                <tr>
                                  <td>Marital Status</td>
                                  <td>{item?.marital_status}</td>
                                </tr>
                              )}

                              {item?.passport_type && (
                                <tr>
                                  <td>Passport Type</td>
                                  <td>{item?.passport_type}</td>
                                </tr>
                              )}

                              {item?.passport_number && (
                                <tr>
                                  <td>Passport Number</td>
                                  <td>{item?.passport_number}</td>
                                </tr>
                              )}

                              {item?.passport_place_of_issue && (
                                <tr>
                                  <td>Passport Place of Issue</td>
                                  <td>{item?.passport_place_of_issue}</td>
                                </tr>
                              )}

                              {item?.passport_issue_date && (
                                <tr>
                                  <td>Passport Issue Date</td>
                                  <td>{item?.passport_issue_date}</td>
                                </tr>
                              )}

                              {item?.passport_expiry_date && (
                                <tr>
                                  <td>Passport Expiry Date</td>
                                  <td>{item?.passport_expiry_date}</td>
                                </tr>
                              )}


                              {item?.visited_egypt_before && (
                                <tr>
                                  <td>Have you visited Egypt before?</td>
                                  <td>{item?.visited_egypt_before}</td>
                                </tr>
                              )}

                              {item?.last_visit_date_from && (
                                <tr>
                                  <td>Date From</td>
                                  <td>{item?.last_visit_date_from}</td>
                                </tr>
                              )}

                              {item?.last_visit_date_to && (
                                <tr>
                                  <td>Date To</td>
                                  <td>{item?.last_visit_date_to}</td>
                                </tr>
                              )}

                              {item?.last_stay && (
                                <tr>
                                  <td>Where did you stay during this period?</td>
                                  <td>{item?.last_stay}</td>
                                </tr>
                              )}



                              {item?.deported_before && (
                                <tr>
                                  <td>
                                    Have you been deported from Egypt or any
                                    other country before?
                                  </td>
                                  <td>{item?.deported_before}</td>
                                </tr>
                              )}

                              {item?.deported_from && (
                                <tr>
                                  <td>From</td>
                                  <td>{item?.deported_from}</td>
                                </tr>
                              )}

                              {item?.deported_date && (
                                <tr>
                                  <td>Date</td>
                                  <td>{item?.deported_date}</td>
                                </tr>
                              )}

                              {item?.deported_reason && (
                                <tr>
                                  <td>Reason</td>
                                  <td>{item?.deported_reason}</td>
                                </tr>
                              )}

                              {item?.applicant_email && (
                                <tr>
                                  <td>Email</td>
                                  <td>{item?.applicant_email}</td>
                                </tr>
                              )}

                              {item?.applicant_phone && (
                                <tr>
                                  <td>Mobile Number / Cell Number</td>
                                  <td>{item?.applicant_phone}</td>
                                </tr>
                              )}

                              {item?.applicant_address && (
                                <tr>
                                  <td>Address</td>
                                  <td>{item?.applicant_address}</td>
                                </tr>
                              )}

                              {item?.type_of_document && (
                                <tr>
                                  <td>Type of Document</td>
                                  <td>{item?.type_of_document}</td>
                                </tr>
                              )}

                              {item?.passport_page_image && (
                                <tr>
                                  <td>Document Link</td>
                                  <td>
                                    <Link
                                      to={process.env.REACT_APP_FRONT_APP_URL + 'uploads/' + item?.passport_page_image}
                                      target="_blank"
                                    >
                                      Click here
                                    </Link>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </StyledOrderBlock>
                    </>
                  );
                })}
            </div>
          </StyledBlockWrapper>

          <StyledOrderBlock>
            <h2>Host Information</h2>

            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.host_type && (
                    <tr>
                      <td>Host Type</td>
                      <td>{OrderDetails?.host_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.host_name && (
                    <tr>
                      <td>Host Name</td>
                      <td>{OrderDetails?.host_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.host_phone && (
                    <tr>
                      <td>Host Phone(Code + Number)</td>
                      <td>{`${OrderDetails?.host_phone_code}${OrderDetails?.host_phone}`}</td>
                    </tr>
                  )}

                  {OrderDetails?.host_fax && (
                    <tr>
                      <td>Host Fax</td>
                      <td>{OrderDetails?.host_fax}</td>
                    </tr>
                  )}

                  {OrderDetails?.host_email && (
                    <tr>
                      <td>Host Email</td>
                      <td>{OrderDetails?.host_email}</td>
                    </tr>
                  )}

                  {OrderDetails?.host_address && (
                    <tr>
                      <td>Host Address</td>
                      <td>{OrderDetails?.host_address}</td>
                    </tr>
                  )}

                  {OrderDetails?.who_is_paying && (
                    <tr>
                      <td>Who is paying travel and accomodation cost?</td>
                      <td>{OrderDetails?.who_is_paying}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                    OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {item?.us_date}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>
          {OrderDetails?.process_status === "Completed" && (
            <>
              <button
                type="button"
                className="blue-btn"
                data-toggle="modal"
                data-target="#defencePackModal"
                onClick={() => setShowDefence(true)}
              >
                Defence Pack 1
              </button>
              <button
                type="button"
                className="blue-btn"
                data-toggle="modal"
                data-target="#defencePackModal1"
                onClick={() => setShowDefencePack(true)}
              >
                Defence Pack 2
              </button>
              <button
                type="button"
                className="blue-btn"
                data-toggle="modal"
                data-target="#transactionModal"
                onClick={() => SetShowTransaction(true)}
              >
                Enter Transaction ID
              </button>
            </>
          )}
          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
          {/* defence pack */}
          <MyModal
            show={showDefence}
            close={setShowDefence}
            modalHeadTitle={"Defence Pack Content"}
            showFooter={false}
            OrderDetails={OrderDetails}
          >
            <DefenceContent defencePackName={null} />
          </MyModal>
          <MyModal
            show={showDefencePack}
            close={setShowDefencePack}
            modalHeadTitle={"Defence Pack Content"}
            showFooter={false}
            OrderDetails={OrderDetails}
          >
            <DefenceContent defencePackName={"defencePack1"} />
          </MyModal>
          <MyModal
            show={showTransaction}
            close={SetShowTransaction}
            size={"s"}
            modalHeadTitle={"Enter Defence Pack Details:"}
            showFooter={false}
            OrderDetails={OrderDetails}
          >
            <TransactionModal SetShowTransaction={SetShowTransaction} />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;
