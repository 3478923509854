import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock, SearchField } from "./style";
import { useNavigate } from "react-router-dom";
import { getCountryOrders } from "../../redux/orderSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { DebounceInput } from "react-debounce-input";
// import {countries} from "../../../utils/contants";

const CountryOrderList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const countryOrderList = useSelector((state) => state?.order?.countryOrder);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [dateField, setDateField] = useState({
        fromDate: "",
        toDate: ""
    });
    const [searchText, setSearchText] = useState("");
    const isLoading = useSelector((state) => state?.order?.loading);
    const [filterCountry, setFilterCountry] = useState(countryOrderList);

    useEffect(()=> {
        setFilterCountry(countryOrderList);
    },[countryOrderList])


    useEffect(() => {
        const data = { fromDate: dateField.fromDate, toDate: dateField.toDate }
        dispatch(getCountryOrders(data)).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, {
                    className: "toast-message",
                });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Completed Orders | Philippines Admin Portal";
    }, [dispatch, navigate, page, limit]);
    


    const handleSearch = (e) => {
        setSearchText(e.target.value);
        const filterText = e.target.value;
        if (
            countryOrderList &&
            countryOrderList.length > 0 &&
            countryOrderList
        ) {
            if (filterText !== "") {
                const filteredResult = filterCountry
                    ?.filter((item) =>
                        (item?.citizenship_id)
                            .toLowerCase()
                            .includes(filterText.toLocaleLowerCase())
                    )
                    setFilterCountry(filteredResult);
            } else {
                setFilterCountry(countryOrderList);
            }
        }
    };

    const handleSubmit = () => {
        const data = { fromDate: dateField.fromDate, toDate: dateField.toDate }
        dispatch(getCountryOrders(data)).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
    }

    const columns = [
        {
            name: "Country",
            width: "17rem",
            selector: (row) => row?.travelling_from,
            sortable: true,
        },
        {
            name: "Order",
            width: "17rem",
            selector: (row) => row.count,
            sortable: true,
        },
        {
            name: "Standard Orders",
            width: "17rem",
            selector: (row) => row?.standard_count,
            sortable: true,
        },
        {
            name: "Priority Orders",
            width: "17rem",
            selector: (row) => row?.priority_count,
            sortable: true,
        },
        {
            name: "Future Orders",
            width: "17rem",
            selector: (row) => row?.future_count,
            sortable: true,
        },
    ];


    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Country Orders List</h1>
            </StyledPageTitle>
            <form>
                <div className="d-flex">
                    <div className="form-group">
                        <label>
                            From:
                            <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                className="form-control input-class"
                                value={dateField.fromDate}
                                onChange={(e) => setDateField({ ...dateField, fromDate: e.target.value })}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            To:
                            <input
                                type="date"
                                name="toDate"
                                id="toDate"
                                className="form-control input-class"
                                value={dateField.toDate}
                                onChange={(e) => setDateField({ ...dateField, toDate: e.target.value })}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="blue-btn"
                            id="download_btn"
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>

            <SearchField>
                <DebounceInput
                    minLength={0}
                    debounceTimeout={500}
                    type="text"
                    id="search_field"
                    name="search_field"
                    className="search-bar form-control"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search"
                />
            </SearchField>

            <DataTable
                className="uspassport-custom-tbl"
                columns={columns}
                progressPending={isLoading}
                data={filterCountry}
                fixedHeader={true}
                fixedHeaderScrollHeight="550px"
                pagination={filterCountry?.length ? true : false}
                noDataComponent="No records found"
                // buttonWrapper={null}
                setPage={setPage}
                setLimit={setLimit}
                selectableRows={false}
            />
        </StyledContainer>
    );
};

export default CountryOrderList;
