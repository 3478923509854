import styled from "styled-components";

export const StyledHeader = styled.div`
  #topbar {
    background: #ff3f54 !important;
    padding: 5px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 1200px) .container {
      width: 1215px;
    }
    @media (min-width: 992px) .container {
      width: 970px;
    }
    @media (min-width: 768px) .container {
      width: 750px;
    }
  }

  .main-header .goog-te-gadget {
    font-size: 0;
}

.goog-te-gadget {
    font-size: 1px;
    line-height: 0px;
    color: rgb(0, 59, 84);
}

 .goog-te-gadget .goog-te-combo {
    padding: 0px 5px;
    border: 1px solid #ffcd00;
    margin: 1px;
    background-color: yellow;
    color: #2d0303;
}
.goog-te-combo option {
    background: #ffcd00;
}




#topbar,
#topbar a {
  color: #e4e4e4;
}
#topbar {
  font-size: 13px;
  position: relative;
  z-index: 1;
}
.wrap-themesflat-container {
  width: 1372px;
  margin: 0 auto;
  max-width: 90%;
}
.select-lang {
  background-color: #yellow;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
}

& a {
  text-decoration: none;
  color: #e4e4e4;
}
#top-bar-inner {
  padding: 5px 0;
}

.clearfix:before,
.clearfix:after,
.themesflat-container:before,
.themesflat-container:after,
.ult-spacer:before,
.ult-spacer:after,
.ult-animation:before,
.ult-animation:after,
.ult_crlink:before,
.ult_crlink:after {
  clear: both;
  display: table;
  line-height: 0;
  content: "";
}
.top-bar-socials .inner {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
}
#top-bar .top-bar-inner-wrap {
  display: flex;
  justify-content: end;
}
#top-bar .top-bar-content {
  margin: 0;
  text-align: left;
  vertical-align: middle;
}

#top-bar .top-bar-content-right {
  margin: 0 auto;
  text-align: right;
  vertical-align: middle;
}
#top-bar .top-bar-socials {
  @media only screen and (max-width: 767px) {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.goog-te-gadget {
  font-size: 1px;
  line-height: 0px;
  color: rgb(0, 59, 84);
}

.top-bar-socials .address.content {
  margin-right: 15px;

  @media only screen and (max-width: 480px) {
    margin-right: 0;
  }
}

#google_translate_element {
  margin-top: 2px !important;
}

#top-bar .top-bar-socials {
  display: flex;
  justify-content: end;

  @media only screen and (max-width: 550px) {
    display: contents;
  }
}

.top-bar-socials .inner {
}
body .goog-te-gadget .goog-te-combo {
  background-color: #yellow;
}
.goog-te-gadget .goog-te-combo {
  margin: 0;
  background: yellow;
  border: 0;
  padding: 12px 10px;
  color: #000;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  background: #yellow url(img/down-arrow.png) no-repeat center right / 15px;
  background-origin: padding-box;
  padding-right: 6px;
  background-origin: content-box;
  @media only screen and (max-width: 300px) {
    padding: 10px 0px;
    padding-right: 0px;
  }
}
.goog-te-combo {
  background: black;
}
.goog-te-gadget .goog-te-combo {
  // margin: 4px 0;

  @media only screen and (max-width: 300px) {
    margin: 0px 0;
  }
}
.goog-te-combo,
.VIpgJd-ZVi9od-ORHb *,
.VIpgJd-ZVi9od-SmfZ *,
.VIpgJd-ZVi9od-xl07Ob *,
.VIpgJd-ZVi9od-vH1Gmf *,
.VIpgJd-ZVi9od-l9xktf * {
  font-family: arial;
  font-size: 10pt;
}
.goog-te-combo {
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: baseline;

  @media only screen and (max-width: 300px) {
    margin-left: 1px;
    margin-right: 1px;
  }
}

#google_translate_element a {
  display: none !important;
}


.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
`;
